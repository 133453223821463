import React, { createContext, useState, useEffect, useContext } from "react";


const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userN, setUserN] = useState(null);
  const [userPath, setUserPath] = useState(null);


  useEffect(() => {
    const token = localStorage.getItem("authToken");
    const storedUserId = localStorage.getItem("userId");
    const storeUser = localStorage.getItem("userN");
    const storePath = localStorage.getItem("userPath");
   

    if (token && storedUserId && storeUser && storePath) {
      setIsAuthenticated(true);
      setUserId(storedUserId);
      setUserN(storeUser);
      setUserPath(storePath);
    }
  }, []);

  const login = async (id,user,path) => {
    setIsAuthenticated(true);
    setUserId(id);
    setUserN(user);
    setUserPath(path);
    localStorage.setItem("authToken", "your-auth-token");
    localStorage.setItem("userId", id);
    localStorage.setItem("userN", user);
    localStorage.setItem("userPath",path);
  };

  const logout = () => {
    setIsAuthenticated(false);
    setUserId(null);
    localStorage.removeItem("authToken");
    localStorage.removeItem("userId");
    localStorage.removeItem("username");
    localStorage.removeItem("userPath");
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, userId, userN, userPath, login ,logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);