import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../services/AuthContext";
import api from "../services/Api";
import "../css/Login.css";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { login} = useAuth();

  const handleLogin = async (e) => {
  e.preventDefault();
  
  try {
    const response = await api.post("/login", {
      username,
      password,
    });

    if (response.status === 200) {
      const userId = response.data.id;
      const userN = response.data.username;
      const userPath = response.data.path; // Supondo que o userId esteja na resposta
      login(userId,userN,userPath); // Passa o userId para o login
      navigate("/dashboard");
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      setTimeout(() => setError(""), 2000);
      setError("Usuário ou senha incorretos"); // Mensagem do backend
     
    } else {
      setTimeout(() => setError(""), 2000);
      setError("Sem conexão com o banco");
      
    }
    
  }
};


  return (
    <div className="login-container">
      <div className="login-form">
        <h2>Login</h2>
        <form onSubmit={handleLogin}>
          <input
            type="text"
            placeholder="Login"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="submit">Entrar</button>
        </form>
        {error && <p>{error}</p>}
      </div>
    </div>
  );
};

export default Login;
