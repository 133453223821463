import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "../../App.css";

import Sidebar from "./Sidebar";
import Login from "../views/Login";
import Dashboard from "../views/Dashboard";
import Cadastros from "../views/Cadastros";
import Entidades from "../views/Entidades";
import Estoque from "../views/Estoque";
import OrdemServicos from "../views/OrdemServicos";
import Relatorios from "../views/Relatorios";
import Configuracoes from "../views/Configuracoes";
import CadastroSecretarias from "../views/Secretarias";
import Header from "./Header";

import PrivateRoute from "./PrivateRoute";
import { AuthProvider, useAuth } from "./AuthContext";

const AppLayout = () => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Login />;
  }

  return (
    <div className="App">
      <Header />
      <Sidebar />
      <div className="content">
        <Routes>
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/cadastros"
            element={
              <PrivateRoute>
                <Cadastros/>
              </PrivateRoute>
            }
          />
          <Route
            path="/entidades"
            element={
              <PrivateRoute>
                <Entidades/>
              </PrivateRoute>
            }
          />
          <Route 
            path="/cadastros/:id" 
            element={
            <PrivateRoute>
              <Cadastros/>
            </PrivateRoute>
            } 
          />
          <Route
            path="/secretarias/:id"
            element={
              <PrivateRoute>
                <CadastroSecretarias />
              </PrivateRoute>
            }
          />
          <Route
            path="/estoque"
            element={
              <PrivateRoute>
                <Estoque />
              </PrivateRoute>
            }
          />
          <Route
            path="/ordem-servicos"
            element={
              <PrivateRoute>
                <OrdemServicos />
              </PrivateRoute>
            }
          />
          <Route
            path="/relatorios"
            element={
              <PrivateRoute>
                <Relatorios />
              </PrivateRoute>
            }
          />
          <Route
            path="/configuracoes"
            element={
              <PrivateRoute>
                <Configuracoes />
              </PrivateRoute>
            }
          />
        </Routes>
      </div>
    </div>
  );
};

const Rotas = () => {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/*" element={<AppLayout />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
};

export default Rotas;
