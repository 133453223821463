// App.js

import React from "react";
import Rotas from "./components/services/Routes";

function App() {
  return <Rotas />;
}

export default App;
