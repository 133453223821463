import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../services/AuthContext"; // Importe o contexto de autenticação
import { MdDelete} from "react-icons/md";
import api from "../services/Api";
import "../css/Style.css";
import Modal from "react-modal";

const NovaEntidade = () => {
  const [descricao, setDescricao] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [cidade, setCidade] = useState("");
  const [ativo,setAtivo] = useState("");
  const [lista,setLista] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const entidadeId = queryParams.get('id');
  const { userId } = useAuth();



  useEffect(() => {
    if (entidadeId) {
      const fetchEntidade = async () => {
        try {        
          const entidadeIdConverter = parseInt(entidadeId, 10);  
          const responseEnt = await api.get(`/buscar_entidade/${entidadeIdConverter}`);
          const entidade = responseEnt.data;
          const responseSec = await api.get('/secretarias');
          const secretarias = responseSec.data;
          const buscasec = secretarias.filter((secretaria) => secretaria.entidade_id === entidadeIdConverter);
          setLista(buscasec);
          setDescricao(entidade.descricao);
          setCnpj(entidade.cnpj);
          setCidade(entidade.cidade);
          setAtivo(entidade.ativo === 1);
        } catch (error) {
          console.error(error);
        }
      };
      fetchEntidade();
    }
  }, [entidadeId]);



  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = new Date().toISOString(); // Gera a data atual no formato ISO
      const statusAtivo = ativo ? '1' : '0';
      const novaEntidade = {
        descricao: descricao.toUpperCase(),
        cnpj: cnpj.toUpperCase(),
        cidade: cidade.toUpperCase(),
        ativo: statusAtivo,
        criado_por: parseInt(userId, 10), // Usa o userId do contexto
        criado_em: data,
      };
      const alteraEntidade = {
        id:parseInt(entidadeId, 10),
        descricao: descricao.toUpperCase(),
        cnpj: cnpj.toUpperCase(),
        cidade: cidade.toUpperCase(),
        ativo: statusAtivo, 
        alterado_por: parseInt(userId, 10), // Usa o userId do contexto
        alterado_em: data,
      };
      if (entidadeId) {
        // Atualização
        await api.put('/entidades',alteraEntidade);
        setModalIsOpen(true);
        setTimeout(() => {
          setModalIsOpen(false);
          navigate('/cadastros');
        }, 2000);
      } else {
        // Cadastro
        await api.post("/entidades",novaEntidade);
        setModalIsOpen(true);
        setTimeout(() => {
          setModalIsOpen(false);
          navigate('/cadastros');
        }, 2000);
      }
    } catch (error) {
      console.error("Erro ao cadastrar/atualizar entidade:", error);
    }
  };



  const handleNovaSec = async (id) => {
    navigate(`/secretarias/id=${id}`);
  }

  const handleDelete = async () => {}
  const handleEdit = async () => {}


  return (
    <div className="container">
      <div className="title">
        {entidadeId ? "Editar Entidade" : "Nova Entidade"}
      </div>
      <form onSubmit={handleSubmit} className="form">
        <div className="form-group">
          <div className="col">
            <label>Descricao</label>
            <input
              name="descricao"
              type="text"
              value={descricao}
              onChange={(e) => setDescricao(e.target.value)}
              style={{width:400}}
            />
          </div>
         
          <div className="col">
          <label>CNPJ</label>
          <input
            type="text"
            value={cnpj}
            onChange={(e) => setCnpj(e.target.value)}
            maxLength={14}
            style={{width:250}}
          />
          </div>
          <div className="col">
            <label>Cidade</label>
            <input
            name="cidade"
            type="text"
            value={cidade}
            onChange={(e) => setCidade(e.target.value)}
            style={{width: 250}}
          />
          </div>
          <div className="col" >
            <label htmlFor="ativo">Ativo?</label>
            <div className="checkbox">
              <input
                id="ativo"
                name="ativo"
                type="checkbox"
                checked={ativo}
                onChange={(e) => setAtivo(e.target.checked ? 1 : 0)}
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="col">
          {entidadeId ? 
              <button type="button" onClick={() => handleNovaSec(entidadeId)}>Nova Secretaria</button>
            :""
          }
          </div>
        </div>
        {entidadeId ? 
        <div className="tabela-title"><span>Lista de secretarias</span></div>
        :""
        }
         {entidadeId ?
        <div className="form-group">
          <div className="col" style={{height: 300}}>
            <div className="tabela">
            <ul style={{height:300}}>
              {lista.length === 0 ? (
                <p>Nenhuma secretaria encontrada.</p>
              ) : (
                lista.map((secretarias, index) => (
                  <li key={index}>
                    <span onClick={() => handleEdit(secretarias)}>{secretarias.descricao}</span>
                    <MdDelete className="icon" onClick={() => handleDelete(secretarias.id)} />
                  </li>
                ))
              )}
            </ul>
            </div>
          </div>
        </div>:""
        }
        <div className="button_form">
          <button type="submit">{entidadeId ? "Salvar Alterações" : "Cadastrar Entidade"}</button>
        </div>
      </form>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Confirmação de Sucesso"
        style={{
          content: {
            background:'rgba(2, 129, 2, 0.532)',
            color: 'white',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
          },
        }}
      >
        <h2>{entidadeId ? "Alterado com Sucesso!":"Salvo com Sucesso!" }</h2>
      </Modal>
    </div>
  );
};

export default NovaEntidade;
