// src/components/Dashboard.js

import React, { useState, useEffect } from "react";
import api from "../services/Api";
import "../css/Dashboard.css"; // Importando o arquivo CSS

const Dashboard = () => {
  const [data, setData] = useState({
    qtOrdensServicoAbertas: 0,
    qtRelogios: 0,
    qtFontes: 0,
    qtBaterias: 0,
    entidadesCadastradas: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/dashboard" ); 
        setData(response.data);
      } catch (error) {
        console.error("Erro ao buscar dados do Dashboard:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="dashboard-container">
      <div className="dashboard-header">
        <h2>Dashboard</h2>
      </div>
      <div className="dashboard-content">
        <p>
          <span
            className="count-square"
            style={{ backgroundColor: "#007bff" }}
          ></span>
          Ordens de Serviço em Aberto: {data.qtOrdensServicoAbertas}
        </p>
        <p>
          <span
            className="count-square"
            style={{ backgroundColor: "#28a745" }}
          ></span>
          Quantidade de Relógios: {data.qtRelogios}
        </p>
        <p>
          <span
            className="count-square"
            style={{ backgroundColor: "#ffc107" }}
          ></span>
          Quantidade de Fontes: {data.qtFontes}
        </p>
        <p>
          <span
            className="count-square"
            style={{ backgroundColor: "#dc3545" }}
          ></span>
          Quantidade de Baterias: {data.qtBaterias}
        </p>
        <p>
          <span
            className="count-square"
            style={{ backgroundColor: "#6c757d" }}
          ></span>
          Entidades Cadastradas: {data.entidadesCadastradas}
        </p>
      </div>
    </div>
  );
};

export default Dashboard;
