// Relatorios.js

import React from "react";

const Relatorios = () => {
  return (
    <div>
      <h2>Relatórios</h2>
      <p>Em desenvolvimento...</p>
    </div>
  );
};

export default Relatorios;
