// Configuracoes.js

import React, { useState } from "react";

const Configuracoes = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [nomeUsuario, setNomeUsuario] = useState("");
  const [cpfUsuario, setCpfUsuario] = useState("");
  const [emailUsuario, setEmailUsuario] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const novoUsuario = {
      nome: nomeUsuario,
      cpf: cpfUsuario,
      email: emailUsuario,
    };
    setUsuarios([...usuarios, novoUsuario]);
    // Limpar campos após cadastro
    setNomeUsuario("");
    setCpfUsuario("");
    setEmailUsuario("");
  };

  return (
    <div>
      <h2>Configurações</h2>
      <div className="submenu">
        <ul>
          <li>Usuários</li>
          <li>Sair</li>
        </ul>
      </div>
      <div>
        <h3>Cadastro de Usuários</h3>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Nome Completo"
            value={nomeUsuario}
            onChange={(e) => setNomeUsuario(e.target.value)}
          />
          <input
            type="text"
            placeholder="CPF"
            value={cpfUsuario}
            onChange={(e) => setCpfUsuario(e.target.value)}
          />
          <input
            type="email"
            placeholder="E-mail"
            value={emailUsuario}
            onChange={(e) => setEmailUsuario(e.target.value)}
          />
          <button type="submit">Salvar</button>
        </form>
        <div>
          <h4>Lista de Usuários</h4>
          <ul>
            {usuarios.map((usuario, index) => (
              <li key={index}>
                {usuario.nome} - {usuario.cpf} - {usuario.email}
                {/* Adicione ícones de editar e excluir conforme necessário */}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Configuracoes;
