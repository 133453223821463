import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-modal";
import "../css/OrdemServico.css";

Modal.setAppElement("#root");

const OrdemServico = () => {
  const [tipoServico, setTipoServico] = useState("");
  const [descricao, setDescricao] = useState("");
  const [macId, setMacId] = useState(null);
  const [status, setStatus] = useState("");
  const [designado, setDesignado] = useState(null);
  const [relogios, setRelogios] = useState([]);
  const [filteredRelogios, setFilteredRelogios] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [modalPesquisaIsOpen, setModalPesquisaIsOpen] = useState(false);
  const [editingOrdem, setEditingOrdem] = useState(false);

  useEffect(() => {
    const fetchRelogios = async () => {
      try {
        const response = await axios.get("http://localhost:5000/estoque/relogios");
        setRelogios(response.data);
        setFilteredRelogios(response.data);
      } catch (error) {
        console.error("Erro ao buscar relógios:", error);
      }
    };

    fetchRelogios();

    const handleKeyPress = (event) => {
      if (event.key === "F2") {
        event.preventDefault();
        setModalIsOpen(false); // Fecha o modal principal, se aberto
        setModalPesquisaIsOpen(true); // Abre o modal de pesquisa
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearch(value);

    setFilteredRelogios(
      relogios.filter(
        (relogio) => relogio.mac.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  const selectMac = (id) => {
    setMacId(id);
    setModalPesquisaIsOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const novaOrdem = {
        tipo_servico: tipoServico,
        descricao: descricao,
        mac_id: macId,
        status: status,
        designado: designado,
      };

      const response = await axios.post("http://localhost:5000/ordem-servico", novaOrdem);

      // Limpar campos após cadastro
      setTipoServico("");
      setDescricao("");
      setMacId(null);
      setStatus("");
      setDesignado(null);
      alert("Ordem de serviço cadastrada com sucesso!");
    } catch (error) {
      console.error("Erro ao cadastrar ordem de serviço:", error);
    }
  };

  return (
    <div className="ordem-servico-container">
      <h2>Ordem de Serviço</h2>
      <form onSubmit={handleSubmit}>
        <select value={tipoServico} onChange={(e) => setTipoServico(e.target.value)}>
          {editingOrdem ? (
            <option value={tipoServico}>
              {tipoServico === "M" && "Manutenção"}
              {tipoServico === "E" && "Troca de Equipamento"}
              {tipoServico === "T" && "Treinamento"}
            </option>
          ) : (
            <option value="">Selecione o Serviço</option>
          )}
          {!editingOrdem && (
            <>
              <option value="M">Manutenção</option>
              <option value="E">Troca de Equipamento</option>
              <option value="T">Treinamento</option>
            </>
          )}
        </select>
        <textarea
          placeholder="Descrição"
          value={descricao}
          onChange={(e) => setDescricao(e.target.value)}
        />
        <input
          type="text"
          placeholder="MAC"
          value={macId}
          readOnly
          disabled={tipoServico !== "E"} // Desabilita o input quando o tipo de serviço não for "E"
          onKeyDown={(e) => tipoServico === "E" && e.key === "F2" && setModalPesquisaIsOpen(true)}
        />
        <input
          type="text"
          placeholder="Status"
          value={status}
          onChange={(e) => setStatus(e.target.value)}
        />
        <input
          type="number"
          placeholder="Designado"
          value={designado}
          onChange={(e) => setDesignado(e.target.value)}
        />
        <button type="submit">Cadastrar Ordem</button>
      </form>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        overlayClassName="modal-overlay"
        className="modal-content"
        contentLabel="Modal de Cadastro"
      >
        {/* Conteúdo do seu modal principal */}
      </Modal>
      <Modal
        isOpen={modalPesquisaIsOpen}
        onRequestClose={() => setModalPesquisaIsOpen(false)}
        overlayClassName="modal-overlay"
        className="modal-content"
        contentLabel="Modal de Seleção de MAC"
      >
        <h2>Selecione o MAC</h2>
        <input
          type="text"
          placeholder="Buscar por MAC"
          value={search}
          onChange={handleSearch}
        />
        <ul>
          {filteredRelogios.map((relogio) => (
            <li key={relogio.id} onClick={() => selectMac(relogio.id)}>
              {relogio.mac}
            </li>
          ))}
        </ul>
      </Modal>
    </div>
  );
};

export default OrdemServico;
